.login_form {
  // from ant-radio-button-wrapper border-start-start-radius
  border-radius: 6px;
  // not sure how to set it properly? margin-bottom of button goes away
  padding-bottom: 0.1px;
  // centering the "tabs" & button way easier to do in Component
}

.login_tabs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  label {
    // this is ant-radio-button-wrapper
    flex: 1; // make it grow
    overflow: hidden; // "local auth..." for sm screen
    // and the radio look more like tab | won't work w/o ↙
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
    // css-dev-only-do-not-override | won't work
    //&.ant-radio-button-wrapper-checked {
    //  border-bottom: unset;
    //}
    // ... styled-components could do the trick
  }
}

.input_item {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
