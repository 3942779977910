// styles for layout, sider & content

.layout {
  //
}

.sider {
  // Anaphora has default background #001529
  ul {
    // adding 100vh height would mess the iFramed output
    // background-color: #021221;
  }
  // making a gap between the sider menu and the user menu
  >div:first-child {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
}

.content {
  background-color: white;
  // matching Anaphora styles, simplified
  padding: 16px;
  h1 {
    // closer to actual h2 design
    color: #10239e;
    font-size: 1.5em;
    margin-top: 0;
    margin-bottom: 16px;
  }
  form {
    max-width: 640px;
    margin: 0 auto;
    div[role='separator'] {
      margin-top: calc(32px + 8px); // by default is 16
    }
  }
  .dropButton {
    width: 130px;
  }
}

.instructions {
  margin-top: calc((32px + 8px) / 2); // half the separator below it @ users page
}

.UserMenu {
  // same as the Reset & Save buttons line margin + padding
  padding-bottom: calc(24px + 16px - 4px);
  li:first-child {
    // making it look like a non-menu item, in fact it's a disabled one
    cursor: default;
    color: rgba(0, 0, 0, 0.88) !important;
  }
}


// --- FormListContent ---
.UserCard {
  + .UserCard {
    margin-top: 16px; // matching the top divider's bottom
  }
  &:nth-last-of-type(3) { // there are 2 divs more, so this is the last one
    button {
      &:last-child { // ➕ Add new user
        float: right;
      }
    }
  }
}
.form_list_errors {
  margin-bottom: -8px; // to match 24px bottom margin of other views
  // if we show some errors in this ErrorList will need to adjust styling
}



// styling for the form tooltips
label {
  margin-right: 8px;
  span[role="img"] {
    margin-left: 8px !important;
  }
  &::after{ // colon after the label
    display: none;
  }
}