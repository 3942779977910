$form_max_width: 500px;

.main {
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: $form_max_width;
  margin: auto;
}

.content {
  display: flex;
}

.formContainer {
  flex-grow: 1;
  padding: 32px;
}

.form {
  background-color: white;
  max-width: $form_max_width;
  width: 100%;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
}

.footer {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 32px;
}
