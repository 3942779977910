.dropdown {
  // adding text to the divider
  li[role="separator"] {
    // need to overwrite some of the defaults
    height: calc(32px - 8px) !important;
    background-color: white !important;
    line-height: unset !important; // somehow without it the line will cross the text
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      width: 100%;
      border-top: 1px solid #ccc;
    }
    &:after {
      content: "Restore previous:";
      position: absolute;
      left: 16px;
      padding: 0 8px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      background-color: white !important;
    }
  }
}
