
.logo {
  width: 40px;
  height: 40px;
  display: block;
  &.logo_default { // since AF logo has some empty space on top
    margin-bottom: 8px;
  }
}

// to make it responsible we'll need to overwrite the sizes set by Typography h2:where(...)
// so either selector with actual tag or !important
h2 {
  &.title {
    @media (max-width: 500px) {
      // this way we only overwrite the smaller one; usual min-width approach is less flexible here
      font-size: 1.5em;
    }
  }
}