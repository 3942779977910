// this could be globals if have more rules like this
body {
  margin: 0;
}

html,
body {
  // using min-height 100vh to keep the scroll on the html.
  // Use height: 100% instead when having a page that fills exactly the viewport
  // both ensure that the footer is always at the bottom of the page
  min-height: 100vh;
}

/* since they use LESS, importing colors etc will be tricky
* https://gist.github.com/Kruemelkatze/057f01b8e15216ae707dc7e6c9061ef7
* so for now let's hardcode | put into local variables */
h1 {
  // color: #002766; // blue-10
  color: #120338; // purple-10
}
h2 {
  // color: #1677ff; // blue-6 | like links, etc primary
  color: #10239e; // geekblue-8
}

.msgToBottom {
  // to make it visible in iFramed mode
  margin-top: 80vh;
}

// animations
@keyframes shake {
  0%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}
.shake {
  animation: shake 0.3s ease-in-out;
}

// helpers
.disabled_element {
  opacity: 0.3;
  cursor: not-allowed;
  // pointer-events: none;
}