.activation_key_wrapper {
  margin-bottom: 32px;
}

.activation_key_row {
  margin-bottom: 8px;
}

.activation_key_license_link_wrapper {
  margin-top: 16px;
}

.activation_key_license_link_button {
  padding-left: 0;
}